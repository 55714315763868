import { computed } from 'vue'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import type { Item } from '../types/Applications'
import { useApps } from './useApps'
import { useCheckUserPermission } from './useCheckUserPermission'

/**
 * @description function returns apps which user has access to
 */
export const useUserApps = () => {
  const { data: apps, ...useAppsQueryData } = useApps()
  const { tokenData } = useToken()
  const { checkUserPermission } = useCheckUserPermission()

  const userApps = computed(() => {
    if (!apps.value) return []
    const frontendApps = filterFrontendApps(apps.value.data.applications.items)

    if (!tokenData.value) {
      return frontendApps.filter((app) => !app.isRestricted)
    }

    const userAppsIds = new Set(
      Object.keys(tokenData.value.access.apps)
        .map(Number)
        .filter((id) => {
          return checkUserPermission('login', id)
        })
    )
    return frontendApps.filter((app) => userAppsIds.has(app.id))
  })

  const userAppIds = computed(() => userApps.value.map((app) => app.id))

  return { userApps, userAppIds, useAppsQueryData }
}

function filterFrontendApps(apps: Item[]) {
  return apps.filter((app) => app.isFrontendApp && app.name !== 'marketplace')
}
